import { Country } from '@spartacus/core';

export const country: Country = {
  isocode: 'DE',
  name: 'Deutschland',
};

export const subdomain = 'frontend';

export enum BossStorageKeys {
  UC_KITCHEN_PLANNER = 'CARAT_ucconsent',
  STOREFINDER = 'boss_storefinder_stores',
  QTY = 'qty',
  LAST_VIEWED_PRODUCTS = 'boss_last_viewed_products',
  UC_STOREFINDER_GOOGLE_MAPS = 'Google Maps_ucconsent',
  ZIP_CODE = 'boss_zip_code',
  PSP_REDIRECT_URL = 'pspRedirectUrl',
  WISHLIST = 'boss_wishlist',
  MY_MARKET = 'boss_mymarket',
  RECENT_SEARCHES = 'boss_recent_searches',
  SELECTED_PRODUCT_ID = 'selectedProductId',
  CART_ID = '__CID',
  PAYMENT_TYPE = '__p_type',
}
