<nav *ngIf="breadCrumbsAllowed" class="container breadcrumbs" aria-label="Breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [attr.aria-label]="'bossBreadcrumb.goToHomepage' | cxTranslate" [routerLink]="['/']">
        <i
          bossIcon
          class="home-icon"
          [type]="bossIconConfig.HOME"
          [title]="'bossBreadcrumb.home' | cxTranslate"
          aria-hidden="true"
        ></i>
      </a>
    </li>
    <ng-container *ngIf="breadcrumbs?.length; else loading">
      <ng-container *ngFor="let crumb of breadcrumbs; let last = last; trackBy: trackByBreadcrumbLabel">
        <li class="breadcrumb-item">
          <i bossIcon class="separator" [type]="bossIconConfig.ARROW_DROP_RIGHT" aria-hidden="true"></i>
          <a *ngIf="!last; else lastCrumb" [routerLink]="crumb.link">{{ crumb.label }}</a>
          <ng-template #lastCrumb>
            <h1 class="active-breadcrumb" aria-current="page">{{ crumb.label }}</h1>
          </ng-template>
        </li>
      </ng-container>
    </ng-container>
  </ol>
</nav>

<div *ngIf="titleAllowed" class="container pageTitle breadcrumbs-page-title">
  <ng-container *ngIf="page$ | async as page">
    <h2
      *ngIf="
        page &&
        page.pageId !== 'storefinderdetailsPage' &&
        page.pageId !== 'productDetails' &&
        page.type !== 'CategoryPage' &&
        page.template !== 'ContentPage1Template'
      "
      [ngClass]="newTitle ? 'boss-h3 slate text-transform-none new-title' : ''"
    >
      {{ title }}
    </h2>
  </ng-container>
</div>

<ng-template #loading>
  <div class="placeholder-content"></div>
</ng-template>
