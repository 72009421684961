<ng-container *ngIf="product; else loading">
  <ng-container *ngIf="items?.length; else loading">
    <button
      *ngIf="!zoomActivated"
      [attr.aria-label]="'productDetails.wishlist.addThisProduct' | cxTranslate"
      [class.active]="isOnWishlist"
      class="wishlist-btn"
      (click)="toggleFromWishList(product, isOnWishlist)"
      type="button"
      [attr.aria-label]="'common.toggle' | cxTranslate"
    >
      <i
        bossIcon
        [type]="isOnWishlist ? bossIconConfig.HEART_3_FILL : bossIconConfig.HEART_3"
        [iconSize]="30"
        aria-hidden="true"
      ></i>
    </button>

    <ng-container *ngIf="!isMobile">
      <div
        class="main-image"
        [ngClass]="{ zoomed: zoomActivated }"
        #mainImage
        (click)="toogleZoom($event)"
        (mousemove)="mouseMoved($event)"
        [style]="canBeZoomed ? 'cursor:' + (zoomActivated ? 'zoom-out' : 'zoom-in') : ''"
      >
        <img
          bossMedia
          [alt]="product.name"
          [container]="activeImage"
          [format]="'product'"
          [isAboveTheFold]="true"
          class="cover"
        />
        <i bossIcon class="zoom-icon" [type]="bossIconConfig.SEARCH" aria-hidden="true"></i>
        <img
          *ngIf="!isMobile"
          [class.hidden]="!zoomActivated || !canBeZoomed"
          bossMedia
          [alt]="product.name"
          [isAboveTheFold]="false"
          [style]="zoomStyles"
          [container]="activeImage"
          [format]="'zoom'"
          class="zoom-image"
        />
      </div>
    </ng-container>

    <div class="boss-product-images-items" [class.my-4]="!isMobile" [class.centered]="!isMobile && items.length <= 4">
      <button
        tabindex="-1"
        class="boss-product-images-button-prev boss-btn boss-btn-primary"
        [class.boss-product-images-button-show]="!isMobile && items.length > 4"
        type="button"
        [attr.aria-label]="'common.previousImage' | cxTranslate"
      >
        <i bossIcon [type]="bossIconConfig.ARROW_LEFT" aria-hidden="true"></i>
      </button>
      <swiper [config]="swiperConfig">
        <ng-template swiperSlide *ngFor="let item of items; index as i; trackBy: trackById">
          <button
            class="boss-product-images-item"
            (click)="setActiveByIndex(i)"
            (mouseenter)="setActiveByIndex(i)"
            [attr.aria-label]="'productDetails.thumbnails.label' | cxTranslate: { index: i + 1 }"
            [class.boss-product-images-item--active]="activeIndex === i"
            tabindex="0"
          >
            <div class="swiper-zoom-container">
              <img bossMedia [container]="item" [format]="'product'" [alt]="item.name" />
            </div>
          </button>
        </ng-template>
      </swiper>
      <button
        tabindex="-1"
        class="boss-product-images-button-next boss-btn boss-btn-primary"
        [class.boss-product-images-button-show]="!isMobile && items.length > 4"
        type="button"
        [attr.aria-label]="'common.nextImage' | cxTranslate"
      >
        <i bossIcon [type]="bossIconConfig.ARROW_RIGHT" aria-hidden="true"></i>
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="boss-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
