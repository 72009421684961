import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  BreadcrumbMeta,
  CmsService,
  Page,
  PageMeta,
  PageMetaService,
  RouterState,
  RoutingService,
} from '@spartacus/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, map, takeUntil } from 'rxjs/operators';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';
import { BossDynamicYieldService } from '../dynamic-yield/boss-dy.service';

@Component({
  selector: 'boss-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossBreadcrumbComponent implements OnInit, OnDestroy {
  page$: Observable<Page>;

  breadcrumbs: BreadcrumbMeta[];

  title: string;

  titleAllowed = true;

  breadCrumbsAllowed = true;

  newTitle = false;

  bossIconConfig = bossIconConfig;

  private titleDisabledRoutes =
    /^(\/newsletter|\/orderConfirmation|\/registration-info|\/favorites|\/storefinder|\/login|\/my-account)/;

  private breadcrumbDisabledRoutes = /^(\/orderConfirmation|\/checkout-login)/;

  private newTitleRoutes = /^(\/checkout-login)/;

  private onDestroy$ = new Subject<void>();

  constructor(
    private pageMetaService: PageMetaService,
    private cmsService: CmsService,
    private cdRef: ChangeDetectorRef,
    private bossDynamicYieldService: BossDynamicYieldService,
    private routingService: RoutingService,
  ) {}

  ngOnInit(): void {
    this.page$ = this.cmsService.getCurrentPage();

    combineLatest([
      this.routingService.getRouterState().pipe(
        map((state: RouterState) => state?.state?.url),
        distinctUntilChanged(),
      ),
      this.pageMetaService.getMeta().pipe(distinctUntilKeyChanged('canonicalUrl')),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([url, meta]: [string, PageMeta]) => {
        const breadcrumbs = meta?.breadcrumbs || [];
        this.breadCrumbsAllowed = !this.breadcrumbDisabledRoutes.test(url);

        if (this.breadCrumbsAllowed) {
          this.breadcrumbs = breadcrumbs;
        } else {
          // Reset breadcrumbs
          this.breadcrumbs = [];
        }

        // Dynamic Yield - categories
        this.bossDynamicYieldService.saveCategoriesToWindow(breadcrumbs);

        this.titleAllowed = !this.titleDisabledRoutes.test(url);

        if (this.titleAllowed) {
          this.newTitle = this.newTitleRoutes.test(url);
          this.title = this.getTitle(meta);
        } else {
          // Reset title
          this.newTitle = false;
          this.title = '';
        }

        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  trackByBreadcrumbLabel(index: number, item: BreadcrumbMeta): string {
    return item.label;
  }

  private getTitle(meta: PageMeta): string {
    const heading = (meta.heading || meta.title) ?? '';

    if (heading.indexOf('[') === 0 && heading.indexOf(']') === heading.length - 1) {
      return '';
    }

    if (this.newTitle) {
      return heading.split(' | ')[0];
    }

    return heading;
  }
}
