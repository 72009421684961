import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  AuthService,
  EventService,
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  Product,
  ProductScope,
  ProductService,
  RoutingService,
  UserIdService,
  WindowRef,
} from '@spartacus/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  publishReplay,
  refCount,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { BossWishlistAddEvent, BossWishlistRemoveEvent } from '../../shared/events';
import { BossWishlist, BossWishlistEntry } from './wishlist.model';
import { BossDynamicYieldService } from '../dynamic-yield/boss-dy.service';
import { BossDYEventType } from '../dynamic-yield/model';
import { BossStorageKeys } from '../../shared/utils/boss-constants';

@Injectable({
  providedIn: 'root',
})
export class BossWishlistService implements OnDestroy {
  wishlist$: Observable<BossWishlist>;

  isProductsOnWishList$: { [key: string]: BehaviorSubject<boolean> } = {};

  private sessionStorage: Storage;

  private defaultWishlistName = 'Default Wishlist';

  private emptyWishlist: BossWishlist = { entries: [] };

  private forceLoad$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private reloadNecessary = true;

  private productForceLoad$: { [key: string]: BehaviorSubject<boolean> } = {};

  private sharedProductWishlist$: { [key: string]: Observable<BossWishlist> } = {};

  private onDestroy$ = new Subject<void>();

  constructor(
    private winRef: WindowRef,
    private routingService: RoutingService,
    private http: HttpClient,
    private userIdService: UserIdService,
    private occEndpointService: OccEndpointsService,
    private authService: AuthService,
    private productService: ProductService,
    private globalMessageService: GlobalMessageService,
    private eventService: EventService,
    private dynamicYieldService: BossDynamicYieldService,
  ) {
    this.sessionStorage = winRef.sessionStorage;
    this.routingService
      .getNextPageContext()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.cleanUp();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  toggleFromWishlist(productCode: string, isOn: boolean = false): void {
    if (isOn) {
      this.removeEntry(productCode);

      this.globalMessageService.add({ key: 'cartItems.removeFromWishlist' }, GlobalMessageType.MSG_TYPE_INFO);
    } else {
      this.addEntry(productCode);

      this.globalMessageService.add({ key: 'cartItems.addToWishlist' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
    }
  }

  // TODO: Remove this
  // This method has no way of working, as `isProductsOnWishList$` is not used anywhere else
  isOnWishlist(code: string): BehaviorSubject<boolean> {
    if (!this.isProductsOnWishList$[code]) {
      this.isProductsOnWishList$[code] = new BehaviorSubject(false);
    }
    return this.isProductsOnWishList$[code];
  }

  isProductOnWishlist(productToCheck: Product): Observable<boolean> {
    return this.getWishlist(productToCheck).pipe(
      map((wishlist: BossWishlist) => wishlist?.entries),
      map((entries: BossWishlistEntry[]) => entries?.some((entry) => entry?.product?.code === productToCheck?.code)),
    );
  }

  productForceLoad(code: string): BehaviorSubject<boolean> {
    if (!this.productForceLoad$[code]) {
      this.productForceLoad$[code] = new BehaviorSubject(false);
    }
    return this.productForceLoad$[code];
  }

  getWishlist(product?: Product): Observable<BossWishlist> {
    if (!product) {
      return this.getWishlistDetails();
    }
    if (!this.sharedProductWishlist$[product.code]) {
      this.sharedProductWishlist$[product.code] = this.getWishlistDetails(product);
    }
    return this.sharedProductWishlist$[product.code];
  }

  addEntry(productCode: string): void {
    combineLatest([
      this.userIdService.getUserId(),
      this.authService.isUserLoggedIn(),
      this.productService.get(productCode, ProductScope.DETAILS),
    ])
      .pipe(filter(([, , product]) => !!product))
      .pipe(take(1))
      .subscribe(([userId, isLoggedIn, product]) => {
        isLoggedIn && userId !== 'anonymous' ? this.addEntryUser(userId, productCode) : this.addEntryAnonymous(product);

        this.dynamicYieldService.triggerEvent({
          name: 'Add to wishlist',
          properties: {
            dyType: BossDYEventType.ADD_TO_WISHLIST,
            productId: productCode,
          },
        });

        this.eventService.dispatch(new BossWishlistAddEvent());
      });
  }

  removeEntry(productCode: string): void {
    combineLatest([this.userIdService.getUserId(), this.authService.isUserLoggedIn()])
      .pipe(takeUntil(this.onDestroy$), take(1))
      .subscribe(([userId, isLoggedIn]) => {
        isLoggedIn && userId !== 'anonymous'
          ? this.removeEntryUser(userId, productCode)
          : this.removeEntryAnonymous(productCode);

        this.dynamicYieldService.triggerEvent({
          name: 'Remove from wishlist',
          properties: {
            productId: productCode,
          },
        });

        this.eventService.dispatch(new BossWishlistRemoveEvent());
      });
  }

  private addEntryUser(userId: string, productCode: string): void {
    const url = `${this.occEndpointService.getBaseUrl()}/users/${userId}/wishlist/${this.defaultWishlistName.replace(
      ' ',
      '%20',
    )}/product/${productCode}`;

    this.http
      .post(url, {})
      .pipe(shareReplay(1), takeUntil(this.onDestroy$))
      .subscribe(
        () => {
          this.reloadNecessary = true;
          this.productForceLoad(productCode).next(true);
          this.forceLoad$.next(true);
        },
        (error) => {
          console.log('error', error);
        },
      );
  }

  private addEntryAnonymous(product: Product): void {
    const wishlist = this.getAnonymousWishlist();

    if (wishlist?.entries?.some((entry: BossWishlistEntry) => entry.product?.code === product?.code)) {
      return;
    }

    wishlist?.entries?.push({ product });
    this.saveAnonymousWishlist(wishlist);

    this.reloadNecessary = true;
    this.productForceLoad(product.code).next(true);
    this.forceLoad$.next(true);
  }

  private removeEntryUser(userId: string, productCode: string): void {
    const url = `${this.occEndpointService.getBaseUrl()}/users/${userId}/wishlist/${this.defaultWishlistName.replace(
      ' ',
      '%20',
    )}/product/${productCode}`;

    this.http
      .delete(url)
      .pipe(shareReplay(1), takeUntil(this.onDestroy$))
      .subscribe(
        () => {
          this.reloadNecessary = true;
          this.productForceLoad(productCode).next(true);
          this.forceLoad$.next(true);
        },
        (error) => {
          console.log('error', error);
        },
      );
  }

  private removeEntryAnonymous(productCode: string, reload = true): void {
    const wishlist = this.getAnonymousWishlist();

    wishlist.entries = wishlist.entries.filter((entry: BossWishlistEntry) => entry?.product?.code !== productCode);
    this.saveAnonymousWishlist(wishlist);

    if (reload) {
      this.reloadNecessary = true;
      this.productForceLoad(productCode).next(true);
      this.forceLoad$.next(true);
    }
  }

  private saveAnonymousWishlist(anonymousWishlist: BossWishlist): void {
    if (this.winRef.isBrowser()) {
      this.sessionStorage.setItem(BossStorageKeys.WISHLIST, JSON.stringify(anonymousWishlist));
    }
  }

  private getAnonymousWishlist(): BossWishlist {
    if (this.winRef.isBrowser()) {
      const wishlist = this.sessionStorage.getItem(BossStorageKeys.WISHLIST);

      if (wishlist) return JSON.parse(wishlist);
    }

    return this.emptyWishlist;
  }

  private cleanUp(): void {
    this.productForceLoad$ = {};
    this.sharedProductWishlist$ = {};
    this.isProductsOnWishList$ = {};
  }

  private getWishlistDetails(product?: Product): Observable<BossWishlist> {
    const force = product?.code ? this.productForceLoad(product.code) : this.forceLoad$;
    return combineLatest([this.userIdService.getUserId(), this.authService.isUserLoggedIn(), force]).pipe(
      distinctUntilChanged(),
      tap(([userId, isLoggedIn]) => {
        // merging anonymous wishlist with user wishlist
        if (isLoggedIn && userId !== 'anonymous') {
          const wishlist = this.getAnonymousWishlist();
          const wishlistEntriesCount = wishlist?.entries?.length;
          if (wishlist && wishlistEntriesCount) {
            wishlist?.entries
              .map(({ product }, index: number) => {
                const code = product?.code;
                const url = `${this.occEndpointService.getBaseUrl()}/users/${userId}/wishlist/${this.defaultWishlistName.replace(
                  ' ',
                  '%20',
                )}/product/${code}`;

                return this.http.post(url, {}).pipe(
                  tap(
                    () => {
                      if (index === wishlistEntriesCount - 1) {
                        this.productForceLoad(product.code).next(true);
                        this.forceLoad$.next(true);
                      }
                    },
                    (error) => {
                      console.log('Wishlist error', error);
                    },
                  ),
                );
              })
              .reverse()
              .reduce((cur, acc) => acc.pipe(mergeMap(() => cur)))
              .pipe(takeUntil(this.onDestroy$), take(1))
              .subscribe(() => {});

            wishlist.entries.forEach((entry: BossWishlistEntry) => {
              this.removeEntryAnonymous(entry.product?.code, false);
            });
          }
        }
      }),
      switchMap(([userId, isLoggedIn, forceLoad$]) => {
        if (isLoggedIn && userId !== 'anonymous') {
          if ((!this.wishlist$ || forceLoad$) && this.reloadNecessary) {
            this.wishlist$ = this.http
              .get(`${this.occEndpointService.getBaseUrl()}/users/${userId}/defaultWishlist`)
              .pipe(publishReplay(1), refCount());
          }
          return this.wishlist$;
        } else {
          return of(this.getAnonymousWishlist());
        }
      }),
      tap((wishlist: BossWishlist) => {
        // wishlist default name handle
        if (wishlist?.isDefault && wishlist?.name) {
          if (this.defaultWishlistName !== wishlist.name) {
            this.defaultWishlistName = wishlist.name;
          }
        }
      }),
    );
  }
}
