import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, WindowRef } from '@spartacus/core';
import { StoreFinderSearchPage } from '@spartacus/storefinder/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BossPointOfService } from '../../../../shared/models';
import { BossStorageKeys } from '../../../../shared/utils/boss-constants';

export interface FetchStoresModel {
  stores: BossPointOfService[];
}

@Injectable({ providedIn: 'root' })
export class BossStorefinderListService {
  private storesQuery$ = new ReplaySubject(1);

  private sessionStorage: Storage;

  constructor(private http: HttpClient, private occEndpoints: OccEndpointsService, private winRef: WindowRef) {
    this.sessionStorage = winRef.sessionStorage;
  }

  nextQuery(query: string): void {
    this.storesQuery$.next(query);
  }

  getStores(): Observable<BossPointOfService[]> {
    return this.storesQuery$.asObservable().pipe(switchMap((query: string) => this.fetchStoresByQuery(query)));
  }

  fetchStoresByQuery(query: string): Observable<BossPointOfService[]> {
    const storesFromStorage =
      this.winRef.isBrowser() && JSON.parse(this.sessionStorage.getItem(BossStorageKeys.STOREFINDER));

    // Cached stores
    if (storesFromStorage) {
      return of(storesFromStorage);
    }

    const queryParam = query ? `&query=${query}` : '',
      url = this.occEndpoints.buildUrl(`stores?fields=FULL&pageSize=2000&sort=asc${queryParam}`);

    return this.http.get(url).pipe(
      map((data: FetchStoresModel) => data.stores ?? []),
      tap((data: BossPointOfService[]) => {
        if (this.winRef.isBrowser()) {
          this.winRef.sessionStorage.setItem(BossStorageKeys.STOREFINDER, JSON.stringify(data));
        }
      }),
    );
  }

  findNearbyStoresByCood(latitude: number, longitude: number): Observable<StoreFinderSearchPage> {
    const url = this.occEndpoints.buildUrl(
      `stores?fields=FULL&pageSize=2000&sort=asc&latitude=${latitude}&longitude=${longitude}`,
    );
    return this.http.get(url);
  }
}
