export const common = {
  sorting: {
    date: 'Datum',
    orderNumber: 'Bestellnummer',
    rma: 'Retourennummer',
  },
  bossMiniCart: {
    title: 'Warenkorb',
    tooltip: {
      filled: {
        amount: 'Menge',
        totalAmount: 'Gesamtsumme',
        totalPlusDelivery: 'inkl. MwSt. zzgl. Versandkosten',
        toCart: 'ZUM WARENKORB',
      },
      title: 'IHR WARENKORB IST LEER.',
      body: {
        part1: 'Sie haben schon Produkte in Ihrem Warenkorb?',
        part2: 'Dann ',
        part3: 'loggen',
        part4: 'Sie sich jetzt ein.',
      },
    },
  },
  bossUserAccount: {
    login: 'login',
    myAccount: 'Mein Profil',
    tooltip: {
      title: 'MEIN KONTO',
      greetings: 'Herzlich Willkommen',
      description: 'Willkommen zurück! Wir freuen uns Sie wiederzusehen. Jetzt anmelden und alle Vorteile nutzen.',
      signUp: 'Registrieren', // 'REGISTRIEREN',
      separator: 'oder',
      logIn: 'Anmelden', // 'ANMELDEN',
      logout: 'Abmelden',
    },
    logoutConfirmation: 'Du hast dich erfolgreich abgemeldet',
    addressBook: 'Mein Adressbuch',
    onlineOrders: 'Online-Bestellungen',
    tracking: 'Auftragsverfolgung',
    customerService: 'Mein Boss Service-Team',
    back: {
      dashboard: 'Zurück zur Kontoübersicht',
    },
    updateProfile: {
      saveChanges: 'Änderungen speichern',
      personalData: 'Persönliche Daten',
    },
    updateEmail: {
      changeEmailAddress: 'E-Mail-Adresse ändern',
      yourEmailAddressIs: 'Deine E-Mail-Adresse lautet',
    },
    updatePassword: {
      changePassword: 'Passwort ändern',
    },
  },
  bossMyMarket: {
    title: 'Mein Markt',
    tooltip: {
      title: 'Online Shop',
      address: 'Adresse',
      contact:
        'Unsere Mitarbeiter bieten Informationen und Beratung in verschiedenen Sprachen an. Sprechen Sie uns an.',
    },
  },
  bossSearchBox: {
    placeholder: 'Ich suche ...',
    button: 'Suchen',
    productsHeader: 'Produkte',
    categoriesHeader: 'Produktkategorien',
    suggestionsHeader: 'Suchvorschläge',
    storeHeader: 'Möbelhaus',
    serviceHeader: 'Service&Karriere',
    moreContent: 'Weitere Inhalte',
    recentSearches: 'Zuletzt gesucht',
    noMatches: 'Keine Treffer',
    popularSearchTerms: 'Beliebte Suchbegriffe',
  },
  bossWishListIcon: {
    title: 'merkliste',
    tooltip: {
      title: 'IHRE MERKLISTE IST LEER.',
      body: {
        part1: 'Sie haben schon Produkte auf Ihrer Merkliste?',
        part2: 'Dann ',
        part3: 'loggen',
        part4: 'Sie sich jetzt ein.',
      },
    },
  },
  bossNewsletter: {
    title: 'Newsletter',
    description: 'Newsletter abonnieren & sparen',
    email: 'E-Mail-Adresse',
    salutation: 'Anrede',
    firstName: 'Vorname',
    lastName: 'Nachname',
    success: {
      part1: 'Sie haben sich erfolgreich mit ',
      part2: ' am Newsletter angemeldet.',
      part3:
        'Sie erhalten in Kürze eine E-Mail mit einem Bestätigungslink. Bitte klicken Sie den Link an, um künftig den SB-Möbel Boss Newsletter mit aktuellen Angeboten und Informationen zu erhalten.',
    },
    invalidEmail: 'Ungültige E-Mail-Adresse',
    alreadyRegistered: '"Ihre E-Mail-Adresse ist schon registriert."',
    serviceError:
      '"Leider konnte Ihre E-Mail-Adresse aufgrund eines internen Fehlers nicht registriert werden. Versuchen Sie es bitte später noch einmal."',
    headline: '10 € Gutschein* sichern und anmelden',
    subline: 'Erfahren Sie regelmäßig von unseren aktuellen Angeboten und Aktionen',
    list: {
      part1: 'Die aktuellsten Prospekte',
      part2: 'Rabattaktionen, Angebote und Gewinnspiele',
      part3: 'Die neuesten Möbel-Trends auf einen Blick',
    },
    emailLabel: 'E-Mail-Adresse*',
    submit: 'Kostenlos anmelden',
    hint: 'Hinweis: Ihre Daten werden nicht an Dritte weitergegeben.<br /> Die Abmeldung vom Newsletter ist jederzeit möglich.',
    hintShort: 'Hinweis: Ihre Daten werden nicht an Dritte weitergegeben.',
    unsubscribe: 'Jetzt Abmelden',
    unsubscribeHint: {
      part1: 'Sie wollen unsere E-Mails abbestellen?',
      part2: 'Klicken Sie einfach auf den in jeder unserer E-Mails enthaltenen Abmeldelink.',
    },
    visualAlt: '10€ Gutschein** für Ihren nächsten Einkauf',
    codeAsteriks:
      '** Zur einmaligen Einlösung. Pro Person und Bestellung darf nur ein Gutschein eingelöst werden. Nicht kombinierbar mit anderen Aktionen.  Nur online einlösbar',
    age: 'Ja, ich möchte per E-Mail-Newsletter über Trends, Aktionen, Gutscheine und mehr informiert werden. Ich bestätige, älter als 16 Jahre zu sein und willige ferner ein, dass das Öffnungs- und Klickverhalten der E-Mail-Newsletter verarbeitet werden darf. Eine Abmeldung ist jederzeit möglich. Mehr zum Datenschutz unter ',
    privacy: {
      part1: 'Ausführlichere Details zum Thema Newsletter finden Sie in unseren ',
      part2: 'Datenschutzhinweisen.',
    },
    newsletterPopup: {
      emailRequired: 'E-Mailadresse*',
    },
    storeSelectionPlaceholder: 'Markt wählen ...',
    storeSelectionLabel: 'Auswahl des Marktes',
    optionalInformation: 'Optional:',
    successHeadline: 'Ihre Anmeldung war erfolgreich!',
  },
  bossNewsletterTeaser: {
    email: 'E-Mail-Adresse',
    submit: 'Kostenlos anmelden',
  },
  bossSocialFooter: {
    title: 'Folgen Sie uns auf',
  },
  bossNavHeader: {
    service: 'Dienstleistungen',
    career: 'Karriere',
    delivery: 'Versandpartner',
    contact: 'Kontakt',
    company: 'Unternehmen',
    order: 'Ihre Online-Bestellung',
    payment: 'Bezahlung',
  },
  bossCheckoutFooter: {
    title: ' © SB-Möbel Boss Online Shop GmbH & Co. KG',
    imprint: 'Impressum',
    dataProtection: 'Datenschutz',
    agb: 'AGB',
  },
  bossCheckoutHeader: {
    title: 'Geprüfte Sicherheit beim Online-Kauf',
    logoTitle: 'Günstige Möbel online kaufen bei Möbel BOSS',
  },
  bossProspect: {
    headline: 'UNSERE AKTUELLEN PROSPEKTE',
    subline: 'Für Sie gibt es hier unseren aktuellen Prospekt in der Download Version!',
    postCodeDefault: 'Alle Postleitzahlen',
    storeDefault: 'Markt auswählen',
  },
  formErrors: {
    minlength: 'Nicht genügend Zeichen',
    maxlength: 'Zu viele Zeichen',
    globalMessage: 'Das Formular, das Sie zu senden versuchen, enthält Fehler.',
    required: 'Dieses Feld ist erforderlich',
    cxInvalidEmail: 'Dies ist kein gültiges E-Mail-Format',
    cxInvalidPassword:
      'Das Passwort muss mindestens 6 Zeichen beinhalten, dazu mindestens: 1 Zahl, 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Sonderzeichen.',
    cxPasswordsMustMatch: 'Dein Passwort stimmt nicht überein',
    cxEmailsMustMatch: 'E-Mail-Adresse müssen übereinstimmen',
    cxStarRatingEmpty: 'Bewertung ist erforderlich',
    cxNoSelectedItemToCancel: 'Wählen Sie mindestens ein Produkt',
    cxNegativeAmount: 'Betrag muss gleich oder größer als Null sein',
    cxContainsSpecialCharacters: 'Feld darf keine Sonderzeichen enthalten',
    cxInvalidPhoneNumber:
      'Bitte trage deine Telefonummer als Zahlen ohne Leerzeichen, Sonderzeichen oder Bindestrich ein.',
    pattern: 'Fehlerhafte Eingabe',
    cxInvalidFormat: 'Ungültiges Format',
    date: {
      required: 'Dieses Feld ist erforderlich',
      min: 'Das Datum kann nicht vor {{min}} sein',
      max: 'Das Datum kann nicht nach {{max}} sein',
      pattern: 'Datumsformat yyyy-mm-dd verwenden',
      invalid: 'Verwenden Sie ein gültiges Datum',
      birthday: 'Du musst für die Anmeldung mindestens 16 Jahre alt sein.',
    },
    postalCode: {
      required: 'Dieses Feld ist erforderlich',
      pattern: 'Ihre eingegebene PLZ muss 5-stellig sein.',
    },
    giftCard: {
      invalidNumber: 'Bitte gib die 19 Zahlen deines Codes ein.',
      invalidPin: 'Bitte gib 4 Ziffern ein.',
      invalidAmount: 'Bitte verwende Zahlen und Komma als Eingabe.',
    },
    bossInvalidPhoneNumber: 'Ungültige Eingabe. Bitte verwende nur Zahlen in der Telefonnummer.',
    invalidEntry: 'Bitte überprüfe deine Eingabe.',
  },
  errorHandlers: {
    scriptFailedToLoad: 'Das Skript konnte nicht geladen werden.',
    refreshThePage: 'Bitte aktualisieren Sie die Seite.',
  },
  httpHandlers: {
    badGateway: 'Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
    badRequestPleaseLoginAgain: 'Benutzername und Kennwort stimmen nicht überein. Bitte melde dich erneut an.',
    badRequestOldPasswordIncorrect: 'Dein eingegebenes Passwort ist nicht korrekt.',
    badRequestGuestDuplicateEmail: 'E-Mail-Adresse wird bereits verwendet. Bitte nutzen Sie eine andere E-Mail-Adresse',
    conflict: 'Existiert bereits.',
    forbidden:
      'Sie sind nicht autorisiert, diese Aktion durchzuführen. Bitte kontaktieren Sie Ihren Administrator, wenn Sie glauben, dass es sich um einen Fehler handelt.',
    gatewayTimeout: 'Der Server antwortet nicht, bitte versuchen Sie es später erneut.',
    internalServerError: 'Ein interner Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
    sessionExpired: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
    unknownError: 'Ein unbekannter Fehler ist aufgetreten',
    unauthorized: {
      common: 'Ein unbekannter Autorisierungs-Fehler ist aufgetreten',
      invalid_client: 'Falsche Client Anmeldedaten',
    },
    validationErrors: {
      missing: {
        card_cardType: 'Die ausgewählte Kreditkarte wird nicht unterstützt. Bitte wählen Sie eine andere.',
        card_accountNumber: 'Die eingegebene Kreditkartennummer ist ungültig.',
        card_cvNumber: 'Der eingegebene Sicherheitscode ist ungültig.',
        card_expirationMonth: 'Das eingegebene Ablaufdatum der Kreditkarte ist ungültig.',
        card_expirationYear: 'Das eingegebene Ablaufdatum der Kreditkarte ist ungültig.',
        billTo_firstName: 'Der eingegebene Vorname ist ungültig.',
        billTo_lastName: 'Der eingegebene Nachname ist ungültig.',
        billTo_street1: 'Die eingegebene Adresse ist ungültig.',
        billTo_street2: 'Die eingegebene Adresse ist ungültig.',
        billTo_city: 'Die eingegebene Stadt ist ungültig für diese Kreditkarte.',
        billTo_state: 'Das eingegebene Bundesland/Provinz ist ungültig für diese Kreditkarte.',
        billTo_country: 'Das eingegebene Land/Region ist ungültig für diese Kreditkarte.',
        billTo_postalCode: 'Die eingegebene Postleitzahl ist ungültig für diese Kreditkarte.',
        country: {
          isocode: 'Fehlendes Land/Region',
        },
      },
      invalid: {
        card_expirationMonth: 'Das eingegebene Ablaufdatum der Kreditkarte ist ungültig.',
        firstName: 'Der eingegebene Vorname ist ungültig.',
        lastName: 'Der eingegebene Nachname ist ungültig.',
        password: 'Das eingegebene Passwort ist ungültig.',
        uid: 'UID ist ungültig.',
        code: 'Code ist ungültig.',
        email: 'E-Mail-Adresse ist ungültig.',
        amount: 'Dieses Feld muss größer 0 sein.',
        phone: 'Bitte ergänze deine Telefonnummer um fortzufahren.',
        postalCode: 'Ungültige Postleitzahl',
        giftCardPin: 'Pin oder Code deines Gutscheins ist nicht korrekt',
      },
    },
    cartNotFound: 'Warenkorb nicht gefunden.',
    invalidCodeProvided: 'Ungültiger Code angegeben.',
    moeveError:
      'Etwas ist schief gelaufen, sollte das Problem weiterhin bestehen, kontaktiere bitte unser Kundenservice-Team.',
  },
  bossGeneral: {
    mandatoryFields: '*Pflichtfelder',
  },
  common: {
    cancel: 'Abbrechen',
    delete: 'Löschen',
    remove: 'Entfernen',
    edit: 'Bearbeiten',
    back: 'Zurück',
    submit: 'Abschicken',
    continue: 'Weiter',
    save: 'Speichern',
    done: 'Fertig',
    home: 'Start',
    noData: 'Keine Daten.',
    confirm: 'Bestätigen',
    more: 'mehr',
    menu: 'Menu',
    reset: 'Zurücksetzen',
    take: 'Übernehmen',
    from: 'Von',
    to: 'Bis',
    toggleSpecific: 'Toggle {{name}}',
    toggle: 'umschalten',
    close: 'Schließen',
    previous: 'vorheriges',
    next: 'nächstes',
    collapse: 'zuklappen',
    scrollTop: 'zum Anfang scrollen',
  },
  bossMyAccountTracking: {
    headline: 'Auftragsverfolgung',
    subHeadline: 'Du hast eine Bestellung in einem unserer Möbel Boss Märkte aufgegeben?',
    followText:
      'Verfolge bequem online den Status deiner Bestellung. Die hierfür nötigen Daten findest du auf deiner Auftragsbestätigung.',
    orders: 'Informationen über deine Online-Bestellungen findest du&nbsp;<a href="/my-account/orders">hier</a>.',
    required: '* Pflichtfelder',
    surnamePlaceholder: 'Nachname',
    salesContractIdPlaceholder: 'Auftragsnummer',
    erpCustomerIdPlaceholder: 'Kundennummer',
    backToOverview: 'Zurück zur Übersicht',
    overviewHeadline: 'Bestelldetails',
    table: {
      orderNumber: 'Bestellnummer',
      date: 'Bestelldatum',
      deliveryStatus: 'Lieferstatus',
      totalExpense: 'Gesamtsumme',
      storeName: 'Möbelhaus',
      numberOfProducts: 'Produktanzahl',
    },
    resetOrderTrackingDetails: 'Neue Abfrage starten',
    submit: 'Abfrage starten',
  },
  bossMyAccount: {
    title: 'Hallo ',
    customerId: 'Kundennummer',
  },
  pageMetaResolver: {
    category: {
      title: '{{count}} Ergebnis für {{query}}',
      title_plural: '{{count}} Ergebnisse für {{query}}',
    },
    search: {
      title: '{{count}} Ergebnis für "{{query}}"',
      title_plural: '{{count}} Ergebnisse für "{{query}}"',
      findProductTitle: '{{count}} Ergebnis für Coupon "{{coupon}}"',
      findProductTitle_plural: '{{count}} Ergebnisse für Coupon "{{coupon}}"',
      default_title: 'Alle Produkte',
    },
  },
  bossBreadcrumb: {
    home: 'Möbel Boss',
    goToHomepage: 'Go to homepage',
  },
  bossProduct: {
    price: {
      msrp: 'UVP',
    },
  },
  bossBackToTop: {
    label: 'Go back to top of the page',
  },
  salesBanner: {
    copy: 'Code kopieren',
  },
  bossKitchenPlanner: {
    title: 'Küchenplaner',
    btn: 'Küchenplaner anzeigen',
  },
};
