import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CmsSearchBoxComponent, RoutingService, WindowRef } from '@spartacus/core';
import {
  BREAKPOINT,
  BreakpointService,
  CmsComponentData,
  SearchBoxComponent,
  SearchBoxConfig,
} from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  BossOccSuggestionsCategory,
  BossOccSuggestionsMarkets,
  BossOccSuggestionsServiceAndCareer,
  BossSearchResults,
} from './boss-search-box.model';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';
import { BossSearchBoxComponentService } from './boss-search-box.component.service';
import { BossDynamicYieldService } from '../dynamic-yield/boss-dy.service';
import { BossDYEventType } from '../dynamic-yield/model';
import { BossStorageKeys } from '../../shared/utils/boss-constants';

@Component({
  selector: 'boss-search-box',
  templateUrl: './boss-search-box.component.html',
  styleUrls: ['./boss-search-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossSearchBoxComponent extends SearchBoxComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  ICONS = bossIconConfig;

  recentSearches: string[] = [];

  popularSearches: string[] = [];

  searchCategories: BossOccSuggestionsCategory[] = [];

  searchMarkets: BossOccSuggestionsMarkets[] = [];

  searchServiceAndCareer: BossOccSuggestionsServiceAndCareer[] = [];

  noSearchResults: boolean = false;

  enoughCharsInQuery: boolean = false;

  isMobile: boolean;

  isOpen: boolean;

  private sessionStorage: Storage;

  private onDestroy$ = new Subject<void>();

  private search$: Subject<string> = new Subject();

  config: SearchBoxConfig = {
    minCharactersBeforeRequest: 3,
    displayProducts: true,
    displaySuggestions: true,
    maxProducts: 4,
    maxSuggestions: 3,
    displayProductImages: true,
  };

  constructor(
    componentData: CmsComponentData<CmsSearchBoxComponent>,
    winRef: WindowRef,
    routingService: RoutingService,
    private cdRef: ChangeDetectorRef,
    private bossSearchBoxComponentService: BossSearchBoxComponentService,
    private breakpointService: BreakpointService,
    private dynamicYieldService: BossDynamicYieldService,
  ) {
    super(bossSearchBoxComponentService, componentData, winRef, routingService);
    this.sessionStorage = winRef?.sessionStorage;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.componentData.data$
      .pipe(filter(Boolean), takeUntil(this.onDestroy$))
      .subscribe((data: CmsSearchBoxComponent) => {
        this.popularSearches = data.searchBoxItems?.split(/\s+/)?.map((item) => item?.toLowerCase());

        this.cdRef.detectChanges();
      });

    if (this.winRef.isBrowser()) {
      this.getRecentSearches();
    }

    this.search$
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((query: string) => {
        this.enoughCharsInQuery = query.length >= this.config.minCharactersBeforeRequest;

        this.searchBoxComponentService.search(query, this.config);
      });

    this.breakpointService
      .isDown(BREAKPOINT.md)
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;

        // Close results on viewport change
        this.searchBoxComponentService.toggleBodyClass('searchbox-is-active', false);

        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  bossResults$: Observable<BossSearchResults> = this.config$.pipe(
    switchMap((config) => this.bossSearchBoxComponentService.bossGetResults(config)),
    tap((results: BossSearchResults) => {
      const { categories, market, products, serviceAndCareer, suggestions } = results;

      this.searchCategories = categories || [];
      this.searchMarkets = market || [];
      this.searchServiceAndCareer = serviceAndCareer || [];

      this.noSearchResults = !(
        categories?.length === 0 &&
        market?.length === 0 &&
        products?.length === 0 &&
        serviceAndCareer?.length === 0 &&
        suggestions?.suggestions?.length === 0
      );
    }),
  );

  open(): void {
    if (!this.isOpen) {
      super.open();

      this.isOpen = true;

      if (this.isMobile && this.winRef.isBrowser()) window.scrollTo(0, 0);

      this.cdRef.detectChanges();
    }
  }

  close(event: UIEvent, force?: boolean, clearValueOnClose = true): void {
    super.close(event, force);

    this.isOpen = false;

    if (clearValueOnClose) this.resetSearch();

    this.cdRef.detectChanges();
  }

  launchSearchResult(event: UIEvent, query: string): void {
    this.dynamicYieldService.triggerEvent({
      name: 'Keyword search',
      properties: {
        dyType: BossDYEventType.KEYWORD_SEARCH,
        keywords: query,
      },
    });

    this.close(event, true);
    super.launchSearchResult(event, query);
  }

  saveRecentSearch(value: string): void {
    if (this.recentSearches.includes(value)) return;

    if (this.recentSearches.length === 3) {
      this.recentSearches.shift();
    }

    this.recentSearches.push(value);

    if (this.winRef.isBrowser()) {
      this.sessionStorage.setItem(BossStorageKeys.RECENT_SEARCHES, JSON.stringify(this.recentSearches));
    }
  }

  search(query: string): void {
    this.search$.next(query);
  }

  private getRecentSearches(): void {
    if (this.winRef.isBrowser()) {
      const results = this.sessionStorage.getItem(BossStorageKeys.RECENT_SEARCHES);

      if (results) {
        this.recentSearches = JSON.parse(results);

        this.cdRef.detectChanges();
      }
    }
  }

  private resetSearch(): void {
    this.searchInput.nativeElement.value = '';
    this.searchBoxComponentService.clearResults();
    this.enoughCharsInQuery = false;
  }
}
