import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { BossPointOfService } from '../../../shared/models/store.models';
import { Router } from '@angular/router';
import { BossMyMarketService } from './storefinder.service';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BossStorefinderDetailsService {
  constructor(
    private http: HttpClient,
    private occEndpoints: OccEndpointsService,
    private router: Router,
    private bossMyMarketService: BossMyMarketService,
  ) {}

  getStoreDetails(storeId: string): Observable<BossPointOfService | HttpErrorResponse> {
    const id = storeId ?? '',
      url = this.occEndpoints.buildUrl(`stores/${id}?fields=FULL`);
    return this.http.get(url).pipe(catchError((e) => this.handleError(e)));
  }

  private handleError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.bossMyMarketService.changestoreAvailable(true);
    this.router.navigate(['storefinder'], {
      queryParams: {
        notavailable: true,
      },
    });
    return throwError(error);
  }
}
